import React from 'react';
import { Link } from 'gatsby';
import { SecondaryHeading } from '../../styles/typography';

import { Main, BackgroundGradient } from '../../styles/background';
import { Content } from '../../components/layouts/PageContainer/PageContainer';
import { Header } from '../../components/layouts/HeaderComponent';
import { Footer } from '../../components/layouts/Footer';
import { PageContainer } from '../../components/containers/PageContainer';
import { HelmetContainer } from '../../components/pageComponents/helmet';
import { PageContent } from '../../components/layouts/PageContent';
import { ContactCalloutWithImage } from '../../components/layouts/ContactCalloutWithImage';
// @ts-ignore
import { ContactBlock } from '../../components/layouts/ContactBlock';
import { OnDemandManufacturingHeader } from '../../components/pageComponents/onDemandManufacturing/OnDemandManufacturingHeader';
import { TrustedBy } from '../../components/layouts/TrustedBy';
import { ListContainer } from '../../components/pageComponents/AIInternship/styles';
import { Related } from '../../components/layouts/Related';
import { CustomerStorySpotlight } from '../../components/layouts/CustomerStorySpotlight/CustomerStorySpotlight';
import { getForm } from '../../components/layouts/ContactModal/utils';
import { OnDemandManufacturingDownloadButton } from '../../components/pageComponents/onDemandManufacturing/OnDemandManufacturingDownloadButton';
import { FullWidthImageCallout } from '../../components/layouts/FullWidthImageCallout/FullWidthImageCallout';
import { FullWidthImageCalloutParagraph } from '../../components/layouts/FullWidthImageCallout/FullWidthImageCalloutStyles';

const OnDemandManufacturing = () => {
  return (
    <PageContainer>
      <HelmetContainer title="规模化按需定制" />
      <BackgroundGradient>
        <Header inverted />
        <Content>
          <OnDemandManufacturingHeader />
        </Content>
      </BackgroundGradient>
      <Main noPadding>
        <TrustedBy
          companies={[
            { name: 'EOS', width: 100 },
            { name: 'sisma', width: 130 },
            { name: 'trumpf', width: 55 },
            { name: 'Prodways', width: 150 },
            { name: '3dsystems', width: 180 },
            { name: 'eplus3d', width: 160 },
            { name: 'nexa3D', width: 130 },
            { name: 'hp', width: 55 },
            { name: 'renishaw', width: 150 },
            { name: 'stratasys', width: 150 }
          ]}
        />

        <Content>
          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '40px' }}>工艺自动化赋能生产流程自动化</SecondaryHeading>}
            text={
              <>
                <p>
                  <a
                    href="https://zh.wikipedia.org/wiki/%E8%AE%A1%E7%AE%97%E6%9C%BA%E8%BE%85%E5%8A%A9%E5%88%B6%E9%80%A0"
                    className="inline"
                    rel="noopener noreferrer nofollow"
                  >
                    计算机辅助制造
                  </a>
                  给制造业带来加工效率、质量的第一次跃升。 随着
                  <Link to="/solution/additive">增材制造</Link>、<Link to="/solution/welding">机器人焊接</Link>{' '}
                  等柔性加工工艺的普及，也大大降低了制造企业开发小量多批次、按需定制业务的制造成本。
                </p>
                <p>智能制造工艺本身拥有极高的自动化，同时与其他或人力、或机器后处理工序组成完整的产线。</p>
                <p>
                  <ListContainer>
                    <li>
                      像这种增材制造的智能制造机器可以实现自动化加工生产，但仍需要人工操作，而且通常需要操作人员具备高超的技术才能胜任。
                    </li>
                  </ListContainer>
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/onDemandManufacturing/3d_systems_healthcare_dmp_printer_fleet_image.jpg"
                alt="dmp printer fleet"
                width="100%"
              />
            }
          />

          <PageContent
            align="left"
            title={
              <SecondaryHeading style={{ marginTop: '40px' }}>全面掌握生产情况，数据驱动业务增长</SecondaryHeading>
            }
            text={
              <>
                <p>
                  实现生产可视化的最佳技术是物联网，利用专业的
                  <Link to="/news/blog/machine-monitoring-up">面对制造业的物联网服务</Link>
                  ，实现：
                </p>
                <p>
                  <ListContainer>
                    <li>实时生产状态远程监控，确保产线正常运行</li>
                    <li>实时警报、通知系统协助团队及时响应，为减少设备停机时间保驾护航</li>
                    <li>生产绩效报告，如OEE等设备稼动率、吞吐量、生产交付周期等，产线效能全知道</li>
                  </ListContainer>
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/onDemandManufacturing/odm_5.png"
                alt="On Demand Manufacturing Bed"
                width="100%"
              />
            }
          />

          <CustomerStorySpotlight
            title="精选用户故事"
            description="这些创新制造企业与Oqton一起走向新一代智能制造。"
            buttonText="其他用户故事"
            cards={[
              {
                url: '../../news/customer-stories/xianfeng-3d',
                label: <img src="/assets/images/logos/xianfeng3d.jpg" alt="Xianfeng3d" width={100} />,
                smallText:
                  '“Oqton Manufacturing OS数字化订单生产管理系统帮助我们稳定交付周期，我们在生产工艺和生产质量方面的管理也得到了大幅度提升，也令我们的客户对我们的服务满意。”',
                customerName: '张长松, 创始人',
                image: {
                  src: '/assets/images/pages/mos/customer_story_xianfeng.png',
                  alt: 'customer story',
                  position: 'bottom'
                },
                hideLink: true
              },
              {
                url: '../../news/customer-stories/custom-color-3d',
                label: (
                  <img src="/assets/images/logos/customColor3DPrinting.png" alt="customColor3DPrinting" width={80} />
                ),
                smallText:
                  '“我认为Oqton正在构建的工具绝对会成为这种大规模生产定制彩色部件的标准。人工智能驱动的自动化可能是最大的助力，我完全期待它将会渗透到我们所做事情的方方面面。”',
                customerName: 'Kent Mages, 创始人',
                image: {
                  src: '/assets/images/customerStories/customColor3DPrinting/preview_customer_story_customColor3DPrinting2.png',
                  alt: 'custom-color-3d',
                  position: 'bottom'
                },
                hideLink: true
              }
            ]}
          />

          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '40px' }}>运营信息化：统筹销售-生产信息链路</SecondaryHeading>}
            text={
              <>
                <p>
                  按需定制的订单有“潮水式”的特点，产品要求、数量、交期各不相同，给生产管理团队带来一定挑战。利用
                  <Link to="/manufacturing-os">生产信息与运营信息的深度集成</Link>进行：
                </p>
                <p>
                  <ListContainer>
                    <li>将生产系统与CRM、ERP等企业系统打通，打通信息孤岛以提升运营效率</li>
                    <li>订单信息一旦变更，生产团队能够快速跟进</li>
                    <li>建立关键生产信息闭环，打造产品生产标准信息体系</li>
                  </ListContainer>
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="../../assets/verticals/additive/additive-12.png"
                alt="Healthcare Screen Shot"
                width="100%"
              />
            }
          />

          <PageContent
            align="left"
            title={<SecondaryHeading style={{ marginTop: '40px' }}>设计数字化赋能产品创新力</SecondaryHeading>}
            text={
              <>
                <p>
                  <ListContainer>
                    <li>
                      利用三维扫描技术打造
                      <Link to="/geomagic-wrap">产品创新知识库</Link>
                    </li>
                    <li>
                      使用
                      <Link to="/geomagic-freeform">自由曲面+力反馈设计</Link>
                      工具，提升定制设计团队生产力​
                    </li>
                    <li>
                      利用
                      <Link to="/geomagic-designx">逆向与CAD设计工具</Link>
                      缩短产品研发时间，领先推向市场
                    </li>
                  </ListContainer>
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '520px', height: 'auto' }}
                src="/assets/images/pages/geomagicDesignX/media_1.png"
                alt="design with digital tech"
              />
            }
          />

          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '40px' }}></SecondaryHeading>}
            text={<p></p>}
            image={
              <img
                style={{ maxWidth: '1128px', height: 'auto' }}
                src="/assets/images/brochures/iotDownloadBtn.png"
                alt="on demand manufacturing ebook"
                width="100%"
              />
            }
            button={
              <OnDemandManufacturingDownloadButton
                title="物联网助您实现工业转型"
                buttonText="阅读电子书"
                iframe={getForm('OnDemandManufacturingDownload')}
              />
            }
          />

          <PageContent
            align="left"
            title={<SecondaryHeading style={{ marginTop: '40px' }}>分布式生产，更快触达市场</SecondaryHeading>}
            text={
              <>
                <p>
                  工厂分布各地、在需求地直接进行生产的分布式制造可带来显著优势。同时，又使企业构成更加灵活、敏捷、有韧性的网络。
                </p>
                <p>
                  利用
                  <Link to="/news/blog/intelli-interaction">云计算技术</Link>
                  ，进行跨多个生产地点的生产运营协同：如工作团队、生产计划、物料采购等
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '520px', height: 'auto' }}
                src="/assets/images/onDemandManufacturing/distributed_factory.jpg"
                alt="Monitor Everything"
              />
            }
          />

          <PageContent
            align="right"
            title={<SecondaryHeading style={{ marginTop: '40px' }}>保证产品质量，打造强劲品牌</SecondaryHeading>}
            text={
              <>
                <p>
                  通过快速高效、完全无损的
                  <Link to="/geomagic-controlx">三维扫描计量技术</Link>
                  ，完成产品质量检查，以提高准确性、降低成本并维持客户期望的质量。
                </p>
                <p>
                  将<Link to="/geomagic-controlx/automated-inspection">检测自动化</Link>
                  ，能够实现自动化质量控制、更高的生产吞吐量。
                </p>
              </>
            }
            image={
              <img
                style={{ maxWidth: '520px', height: 'auto' }}
                src="/assets/images/pages/geomagicControlX/media_3.png"
                alt="quality control"
              />
            }
          />
        </Content>

        <BackgroundGradient>
          <FullWidthImageCallout
            image={
              <img
                src="/assets/images/healthcare/Health-tracability.png"
                alt="Full traceability, planning and scheduling"
                height="512"
              />
            }
            title="人工智能技术赋能制造自动持续优化"
            text={
              <>
                <FullWidthImageCalloutParagraph>
                  面对劳动力短缺、供应链不稳定、产品竞争激烈、质量标准化等诸多挑战，
                  <a
                    href="https://www2.deloitte.com/cn/zh/pages/energy-and-resources/articles/moa-new-tool-of-smart-manufacturing-smartmore-pioneers-moa-practices.html"
                    className="inline"
                    rel="noopener noreferrer nofollow"
                  >
                    更加高效、无人 为干预、可持续的优化能力
                  </a>
                  成为制造企业智能化转型重要助手。
                </FullWidthImageCalloutParagraph>

                <FullWidthImageCalloutParagraph>
                  借助
                  <Link to="/news/blog/auto-factory-era">人工智能技术</Link>
                  ，建立企业制造知识经验库， 纵向整合制造企业内部
                  <Link to="/news/blog/machine-monitoring-up">设备层、控制层、车间管理层数据</Link>
                  ，将生产现场物理世界的数据、事件、 信息转换到数字世界；横向整合
                  <Link to="/news/blog/mes">产品研发设计、生产规划、生产工艺、生产执行等全生命周期</Link>
                  各环节终端信息的集成，以及产业链上下游协同，制造企业得以基于数据分析洞察、找到最佳优化方案，并高效、持续地进行优化。
                </FullWidthImageCalloutParagraph>
              </>
            }
          />
        </BackgroundGradient>

        {/* <Content>
          <CustomerStories
            title="Our clients in the spotlight"
            customerStories={[
              {
                image: '/assets/images/customerStories/teamziereis/img-1.png',
                link: '/news/customer-stories/a-mind-blowing-step-forward/',
                company: 'TeamZiereis',
                title: 'A “mind-blowing step forward” thanks to Oqton'
              },
              {
                image: '/assets/images/customerStories/volkmer/img-1.png',
                link: '/news/customer-stories/supporting-innovation-for-future/',
                company: 'Dental-Labor Volkmer',
                title: 'Supporting innovation for future'
              },
              {
                image: '/assets/images/customerStories/crownCrema/img-1.png',
                link: '/news/customer-stories/insane-cost-and-productivity-improvements',
                company: 'Crown Ceram',
                title: '“Insane” cost and productivity improvements'
              }
            ]}
          />
        </Content> */}

        <ContactBlock title="与Oqton一起打造您的数字化制造服务解决方案" vertical="OnDemandManufacturing" />

        <Related
          title="相关产品"
          articles={[
            {
              title: 'Manufacturing OS',
              description:
                '连接增材制造大生产全流程，Manufacturing OS 生产管理系统集成订单、设计、CAM工艺自动化、设备管理全流程, 重塑新智造。',
              image: { src: '/assets/images/pages/3DXpert/related_1.png', alt: 'placeholder' },
              url: '/manufacturing-os'
            },
            {
              title: 'Geomagic Freeform',
              description:
                '使用软件3DXpert精简优化增材制造流程, 无缝完成轻量化设计、工艺仿真、增材制造工艺优化，实现优质零部件打印。',
              image: { src: '/assets/images/pages/3DXpert/related_3.png', alt: 'placeholder' },
              url: '/geomagic-freeform'
            },
            {
              title: 'Geomagic Design X',
              description: '通过逆向工程软件, 将基于特征的CAD数模与三维扫描数据处理技术相结合。',
              image: { src: '/assets/images/pages/geomagicControlX/designX.png', alt: 'Geomagic Design X' },
              url: '/geomagic-designx'
            },
            {
              title: 'Geomagic Control X',
              description: '与全面的检测和计量软件配合使用，在简洁直观的工作流程中，凭借行业最强大的工具获得优势。',
              image: { src: '/assets/images/pages/geomagicDesignX/controlX.png', alt: 'Geomagic Design X' },
              url: '/geomagic-controlx'
            }
          ]}
        />
      </Main>
      <ContactCalloutWithImage
        title="关注Oqton 堃腾智能制造"
        subTitle="扫码关注公众号，获取更多智造前沿资讯"
        image="/assets/images/contact/stayintouch.png"
        tertiary
      />
      <Footer />
    </PageContainer>
  );
};
export default OnDemandManufacturing;
